const languageesp = {
	"TRANSLATOR.SELECT": "Elige tu idioma",
	"MENU.NEW": "nuevo",
	"MENU.ACTIONS": "Comportamiento",
	"MENU.CREATE_POST": "Crear nueva publicación",
	"MENU.PAGES": "Pages",
	"MENU.FEATURES": "Caracteristicas",
	"MENU.APPS": "Aplicaciones",
	"MENU.DASHBOARD": "Tablero",
	"AUTH.GENERAL.OR": "O",
	"AUTH.GENERAL.SUBMIT_BUTTON": "Enviar",
	"AUTH.GENERAL.NO_ACCOUNT": "No tienes una cuenta?",
	"AUTH.GENERAL.SIGNUP_BUTTON": "Regístrate",
	"AUTH.GENERAL.FORGOT_BUTTON": "Se te olvidó tu contraseña",
	"AUTH.GENERAL.BACK_BUTTON": "Espalda",
	"AUTH.GENERAL.PRIVACY": "Intimidad",
	"AUTH.GENERAL.LEGAL": "Legal",
	"AUTH.GENERAL.CONTACT": "Contacto",
	"AUTH.LOGIN.TITLE": "Crear una cuenta",
	"AUTH.LOGIN.BUTTON": "Registrarse",
	"AUTH.FORGOT.TITLE": "Contraseña olvidada?",
	"AUTH.FORGOT.DESC":
		"Ingrese su correo electrónico para restablecer su contraseña",
	"AUTH.FORGOT.SUCCESS": "Your account has been successfully reset.",
	"AUTH.REGISTER.TITLE": "Sign Up",
	"AUTH.REGISTER.DESC": "Enter your details to create your account",
	"AUTH.REGISTER.SUCCESS": "Your account has been successfuly registered.",
	"AUTH.INPUT.EMAIL": "Email",
	"AUTH.INPUT.FULLNAME": "Fullname",
	"AUTH.INPUT.PASSWORD": "Password",
	"AUTH.INPUT.CONFIRM_PASSWORD": "Confirm Password",
	"AUTH.INPUT.USERNAME": "Usuario",
	"AUTH.VALIDATION.INVALID": "{name} is not valid",
	"AUTH.VALIDATION.REQUIRED": "{name} is required",
	"AUTH.VALIDATION.MIN_LENGTH": "{name}} minimum length is {{min}",
	"AUTH.VALIDATION.AGREEMENT_REQUIRED":
		"Accepting terms & conditions are required",
	"AUTH.VALIDATION.NOT_FOUND": "The requested {name} is not found",
	"AUTH.VALIDATION.INVALID_LOGIN": "The login detail is incorrect",
	"AUTH.VALIDATION.REQUIRED_FIELD": "Campo requerido",
	"AUTH.VALIDATION.MIN_LENGTH_FIELD": "Minimum field length:",
	"AUTH.VALIDATION.MAX_LENGTH_FIELD": "Maximum field length:",
	"AUTH.VALIDATION.INVALID_FIELD": "Field is not valid",
	"ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT": "Selected records count: ",
	"ECOMMERCE.COMMON.ALL": "All",
	"ECOMMERCE.COMMON.SUSPENDED": "Suspended",
	"ECOMMERCE.COMMON.ACTIVE": "Active",
	"ECOMMERCE.COMMON.FILTER": "Filter",
	"ECOMMERCE.COMMON.BY_STATUS": "by Status",
	"ECOMMERCE.COMMON.BY_TYPE": "by Type",
	"ECOMMERCE.COMMON.BUSINESS": "Business",
	"ECOMMERCE.COMMON.INDIVIDUAL": "Individual",
	"ECOMMERCE.COMMON.SEARCH": "Search",
	"ECOMMERCE.COMMON.IN_ALL_FIELDS": "in all fields",
	"ECOMMERCE.ECOMMERCE": "eCommerce",
	"ECOMMERCE.CUSTOMERS.CUSTOMERS": "Customers",
	"ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST": "Customers list",
	"ECOMMERCE.CUSTOMERS.NEW_CUSTOMER": "New Customer",
	"ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE": "Customer Delete",
	"ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION":
		"Are you sure to permanently delete this customer?",
	"ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION":
		"Customer is deleting...",
	"ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE":
		"Customer has been deleted",
	"ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE": "Customers Delete",
	"ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION":
		"Are you sure to permanently delete selected customers?",
	"ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION":
		"Customers are deleting...",
	"ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE":
		"Selected customers have been deleted",
	"ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE":
		"Status has been updated for selected customers",
	"ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE":
		"Selected customers status have successfully been updated",
	"ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE": "Customer has been updated",
	"ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE": "Customer has been created",
};
export default languageesp;
