import axios from "axios";
const sanitize = (resource) => resource.replace(/^\/+|\/+$/gm, "");

export const getAuthorization = (type = "Bearer") => {
	if (typeof window === "undefined") return "";

	const token = localStorage.getItem(
		process.env.REACT_APP_AUTHORIZATION_PROP_NAME
	);

	if (!token) return "";
	return `${type} ${token}`;
};

export const asset = (resource) =>
	`${process.env.REACT_APP_ASSET}/${sanitize(resource)}`;

export const urlServer = (s) => {
	return `${process.env.REACT_APP_URI_SERVER}/${sanitize(s)}`;
};

export const urlApi = (s) => {
	return `${process.env.REACT_APP_URI_SERVER}/api/${sanitize(s)}`;
};

export const api = async (config) => {
	return axios({
		...config,
		url: urlApi(config.url),
		cors: true,
		headers: {
			...config.headers,
			authorization: getAuthorization(),
		},
	}).then(({ data }) => data);
};

export const server = async (config) => {
	return axios({
		...config,
		cors: true,
		url: urlServer(config.url),
	}).then(({ data }) => data);
};
