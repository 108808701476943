import React from "react";
import { CssBaseline } from "@material-ui/core";
import {
	createMuiTheme,
	ThemeProvider as Provider,
} from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
///////////////////////
export const ThemeProvider = ({ children }) => {
	const theme = React.useMemo(
		() =>
			createMuiTheme(
				{
					props: {
						MuiIconButton: {
							disableRipple: true,
							disableFocusRipple: true,
							focusRipple: false,
						},
						MuiButtonBase: {
							disableRipple: true,
							//disableFocusRipple: true,
						},
					},
					palette: {
						type: "light", //isDarkMode ? "dark" : "light",
						primary: {
							main: "#2176FF",
							// "rgb(28,32,33)", //isDarkMode ? "rgb(41,151,255)" : "rgb(28,32,33)",
							contrastText: "rgba(255,255,255,1)",
						},
						secondary: {
							main: "#2FCC71",
							//isDarkMode ? "rgb(28,32,33)" : "rgb(41,151,255)",
							contrastText: "rgba(255,255,255,1)",
						},
						error: {
							main: "#FF5954",
							contrastText: "#FFFFFF",
						},
						background: {
							default: "rgb(240, 241, 245)",
							paper: "#FFFFFF",
							//default: "rgb(242, 244, 247)",
							//default: "rgb(255,255,255)", //"rgb(248,249,251)", //isDarkMode ? "rgb(0,0,0)" : "rgb(248,249,251)", //"#f6f9fc"
							//paper: "rgba(255,255,255,1)", //isDarkMode ? "rgb(26,27,29)" : "#FFFFFF",
						},
						text: {
							primary: "rgb(29,29,31)",
							secondary: "rgba(0,0,0,0.56)",
							//disabled: "rgba(29,29,31,0.38)",
						},
					},
					typography: {
						fontFamily: `"SF Pro Text","Myriad Set Pro","SF Pro Icons","Apple Legacy Chevron","Helvetica Neue","Helvetica","Arial",sans-serif`,
					},
					shape: {
						borderRadius: 6,
					},
					vars: {
						unselectable: {
							userSelect: "none",
							MozUserSelect: "none",
							KhtmlUserSelect: "none",
							WebkitUserSelect: "none",
							OUserSelect: "none",
						},
						appBar: {
							height: 48,
						},
						footerBar: {
							height: 44,
						},
						drawer: {
							width: 300,
							miniWidth: 48,
							sideA: {
								background: {
									backdropFilter: "saturate(180%) blur(20px)",
									backgroundColor: "rgba(255,255,255,0.72)",
								},
								color: "rgba(0,0,0,0.56)",
								colorActive: "rgb(0, 73, 198)",
							},
							sideB: {
								background: {
									backdropFilter: "saturate(180%) blur(20px)",
									backgroundColor: "rgba(255,255,255,0.72)",
								},
							},
						},
						backdropBackground: "rgba(0,0,0,0.08)",

						backgroundBars: {
							backdropFilter: "saturate(180%) blur(20px)",
							backgroundColor: "rgba(240, 241, 245,0.72)",
						},
						backgroundDialogs: {
							backdropFilter: "saturate(180%) blur(20px)",
							backgroundColor: "rgba(255,255,255,0.9)",
						},
					},
				},
				esES
			),
		[]
	);

	return (
		<Provider theme={theme}>
			<CssBaseline />
			{children}
		</Provider>
	);
};
