import React from "react";
import ButtonBase from "./ButtonBase";
///////////////////////////////////////////////
const Button = (props) => (
	<ButtonBase
		{...props}
		disableElevation={true}
		disableFocusRipple={true}
		disableRipple={true}
	/>
);

export default Button;
