import React from "react";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//////////////////////////////////////////////////////
import { AppContext } from "../context/AppContext";
/////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
	backdropDark: {
		zIndex: 3000, // theme.zIndex.drawer + 1,
		color: "#FFF",
	},
	backdropLight: {
		zIndex: 30000, // theme.zIndex.drawer + 1,
		color: "#000",
	},
	backdrop: {
		zIndex: 30000, // theme.zIndex.drawer + 1,
		color: "#000",
	},
}));
/////////////////////////////////
const Loading = () => {
	const { loading } = React.useContext(AppContext);
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};
export default Loading;
