import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
/////////////////////////////////////////////////
import { AppContext } from "../context/AppContext";
///////////////////////////////////////////////
const NotWithAuthRoute = ({ component: Component, redirectTo, ...props }) => {
	const { isAuthenticated } = React.useContext(AppContext);
	return (
		<Route
			{...props}
			render={(routeProps) =>
				!isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect to={redirectTo} />
				)
			}
		/>
	);
};

NotWithAuthRoute.defaultProps = {
	redirectTo: "/dashboard",
};

NotWithAuthRoute.propTypes = {
	redirectTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
		.isRequired,
};

export default NotWithAuthRoute;
