import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
///////////////////////////////////////////////
const ButtonBase = withStyles((theme) => ({
	root: {
		textTransform: "none",
	},
}))(Button);

export default ButtonBase;
