import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { InputBase as InputUI } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

const InputBase = withStyles((theme) => ({
  root: {
    //color: "#3F4254!important",
    border: "1px solid #E4E6EF !important",
    backgroundColor: "#ffffff !important",
    borderRadius: "0.42rem",
    width: "100%",
    height: "calc(1.5em + 1.3rem + 2px)",
  },
  input: {
    padding: theme.spacing(1, 1),
    borderRadius: "0.42rem",
    fontSize: "1rem",
    fontWeight: 400,
    //color: "#3F4254!important",
    lineHeight: "1.5",

    "&:-webkit-autofill,&:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
      {
        WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.paper} inset`,
        //WebkitTextFillColor: "yellow !important",
      },
  },
}))(InputUI);

const Input = ({ label, wrapperProps, errors, icon, ...props }) => {
  return (
    <Box mb={1.5} mt={label ? 0 : 1.5} {...wrapperProps}>
      {label && (
        <Box mb={1}>
          <Typography variant="subtitle1">{label}</Typography>
        </Box>
      )}

      <InputBase
        variant="outlined"
        {...props}
        endAdornment={
          icon ? (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility">
                {icon}
              </IconButton>
            </InputAdornment>
          ) : (
            ""
          )
        }
      />
      {errors && (
        <Typography variant="caption" color="error">
          {errors}
        </Typography>
      )}
    </Box>
  );
};

Input.defaultProps = {
  label: null,
};

Input.propTypes = {
  wrapperProps: PropTypes.object,
  label: PropTypes.string,
};

export default Input;
