import React from "react";
import { Switch, Route } from "react-router-dom";
import NotWithAuthRoute from "./components/NotWithAuthRoute";
import Index from "./pages/Index"
import RegistroUsuario from "./pages/RegistroUsuario";
import ConfirmRegister from "./pages/ConfirmRegister";
import NotFoundPage from "./pages/NotFoundPage";

/////////////////////////////////
const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Index} />
      <NotWithAuthRoute path="/registro/:msisdn" component={RegistroUsuario} />
      <NotWithAuthRoute path="/ConfirmRegister" component={ConfirmRegister} />
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};
export default Routes;
