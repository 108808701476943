import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
///////////////////////////////////////////////////////
const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	content: {
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			maxWidth: 320,
		},
	},
	section: {
		width: "100%",
	},
	logo: {
		height: theme.typography.h3.fontSize,
		width: "auto",
	},
	logoPath: {
		fill: theme.palette.text.primary,
	},
}));
///////////////////////////////////////////////
const NotFoundPage = () => {
	const classes = useStyles();

	return (
		<Grid
			className={classes.root}
			container
			direction="row"
			justify="center"
			alignItems="center"
		>
			<Box py={2} px={2} className={classes.content}>
				<Box className={classes.section}>
					<Typography variant="h5" color="textPrimary" align="center">
						Página no encontrada
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

const page = withRouter(NotFoundPage);

export default page;
