import React from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import * as messages from "./messages";
import { IntlProvider } from "react-intl";
import { AppProvider } from "./context/AppContext";
import { ThemeProvider } from "./context/ThemeContext";
import Loading from "./components/Loading";
import Routes from "./Routes";

const App = ({ basename }) => {
	const lang = "es";
	return (
		<AppProvider>
			<IntlProvider
				messages={messages[lang]}
				locale={lang}
				defaultLocale={lang}
			>
				<ThemeProvider>
					<SnackbarProvider maxSnack={3} hideIconVariant={true}>
						<BrowserRouter basename={basename}>
							<Routes />
						</BrowserRouter>
					</SnackbarProvider>
					<Loading />
				</ThemeProvider>
			</IntlProvider>
		</AppProvider>
	);
};

export default App;
