import React,{useState} from "react";
import { withRouter, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { injectIntl } from "react-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
import useNotifyError from "../hooks/useNotifyError";
import { AppContext } from "../context/AppContext";
import queryString from 'query-string'
///////////////////////////////////////////////
import * as api from "../services/registrousuario";
import Input from "../components/Input";
import Button from "../components/Button";
///////////////////////////////////////////////////////

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    children: {
      backgroundColor: "yellow",
      margin: "2rem",
    },
    boxCentradoVerticalmente: {
      height: 430,
      display: "flex",
      alignItems: "center",
    },
    contenedor: {
      height: "100%",
    },
    img: {
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
    },
  })
);
const schema = yup.object().shape({
  nombre: yup.string().required("Campos Requeridos"),
  apellido: yup.string().required("Campos Requeridos"),
  mail: yup.string().required("Campos Requeridos"),
  telefono: yup.string().required("Campos Requeridos"),
});

///////////////////////////////////////////////

const RegisterPage = (props) => {
  // const { intl } = props;
  const { msisdn } = useParams();
  const { location: { search } } = props;
  const parsed = queryString.parse(search);
  const notifyError = useNotifyError();
  const { setLoading } = React.useContext(AppContext);
  const classes = useStyles();
  const formRef = React.useRef(null);
  const [url,setUrl] = useState("");
  const [token,setToken] = useState("");
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    // defaultValues: {
    //   username: "user",
    //   password: "Horiz0nt3$",
    // },
  });

  const onSubmit = async (form, ev) => {
    try {
      setLoading(true);
      const registro = await api.registro(form);
      setUrl(registro.url_webpay);
      setToken(registro.token);
      localStorage.setItem("idcli", registro.cliente.idCliente);
      localStorage.setItem("token", registro.token);
      return Promise.resolve(true);
    } catch (error) {
      notifyError(error);
    } finally {
      setLoading(false);
      formRef.current.submit();
    }
    return;
  };

  return (
    <Box
      px={8}
      py={8}
      style={{ background: "#FFFFFF" }}
      className={classes.contenedor}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.contenedor}
      >
        <Grid item xs={12} sm={12} md={5} xl={5} lg={5}>
          <Box style={{ background: "#FFFFFF" }}>
            <Box mx={2}>
              <Box style={{ color: "#015bfd", paddingBottom: "20px" }}>
                <Typography variant="h4" align="center">
                  TRA<strong>FOOD</strong>
                </Typography>
              </Box>

              <Box style={{ color: "#015bfd", paddingBottom: "20px" }}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{ color: "#015bfd", fontSize: "x-large" }}
                >
                  <strong>Registro de Usuario</strong>
                </Typography>
              </Box>

              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" name="msisdn" value={msisdn} ref={register} />
                <input type="hidden" name="reference" value={parsed.reference} ref={register} />
                <Input
                  label="Nombre"
                  placeholder="Escribe tu nombre aqui"
                  inputRef={register}
                  name="nombre"
                  errors={errors.nombre?.message}
                />
                <Input
                  label="Apellido"
                  placeholder="Escriba tu apellido aqui"
                  inputRef={register}
                  name="apellido"
                  errors={errors.apellido?.message}
                />
                <Input
                  label="RUT"
                  placeholder="Escriba su RUT aquí"
                  inputRef={register}
                  name="rut"
                  errors={errors.rut?.message}
                />
                <Input
                  type="emai"
                  label="Correo Eléctronico"
                  placeholder="Escribe tu correo electrónico aqui"
                  inputRef={register}
                  name="mail"
                  errors={errors.mail?.message}
                />
                <Input
                  type="tel"
                  label="Teléfono de contacto"
                  placeholder="+569"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  inputRef={register}
                  name="telefono"
                  errors={errors.telefono?.message}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginTop: "5px", padding: "0.70rem" }}
                >
                  Enviar Registro
                </Button>
              </form>
              <form style={{ display: "none" }}
                action={url}
                method="post"
                ref={formRef}
              >
                <Input
                  type="text"
                  name="TBK_TOKEN"
                  value={token}
                />
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
const page = withRouter(injectIntl(RegisterPage));
export default page;
