import React from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from "@material-ui/core";
import Button from "../components/Button";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Filter1OutlinedIcon from '@material-ui/icons/Filter1Outlined';
import Filter2OutlinedIcon from '@material-ui/icons/Filter2Outlined';
import Filter3OutlinedIcon from '@material-ui/icons/Filter3Outlined';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    children: {
      backgroundColor: "yellow",
      margin: "2rem",
    },
    boxCentradoVerticalmente: {
      height: 430,
      display: "flex",
      alignItems: "center",
    },
    contenedor: {
      height: "100%",
    },
    img: {
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
    },
  })
);
///////////////////////////////////////////////

const IndexPage = (props) => {
  const classes = useStyles();
  return (
    <Box
      px={8}
      py={8}
      style={{ background: "#FFFFFF" }}
      className={classes.contenedor}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.contenedor}
      >
        <Grid item xs={12} sm={12} md={5} xl={5} lg={5}>
          <Box style={{ background: "#FFFFFF" }}>
            <Box mx={2} style={{textAlign:"center"}}>
                <Box 
                    component="img"
                    sx={{
                        height: 58,
                        width: 200,
                        maxHeight: { xs: 58, md: 58 },
                        maxWidth: { xs: 200, md: 200 },
                    }}
                    alt="Trafood"
                    src="/TRAFOOD_ICONO.png"
                />
              </Box>
              <Box mx={2} style={{textAlign:"center"}}>
              <Box 
                    component="img"
                    sx={{
                        height: 360,
                        width: 290,
                        maxHeight: { xs: 360, md: 360 },
                        maxWidth: { xs: 290, md: 290 },
                    }}
                    alt="Pedido"
                    src="/pedido.png"
                />
              </Box>
              <Box style={{ paddingBottom: "20px" }}>
                <Typography
                  align="center"
                  style={{ fontSize: "medium" }}
                >
                  Bienvenidos al primer servicio donde puedes pedir tu comida por whatsapp sin Apps.
                </Typography>
              </Box>
              <Box mx={2} style={{textAlign:"center"}}>
                <Button
                  href="https://wa.me/56950266039"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginTop: "5px", padding: "0.70rem" }}
                >
                  Pedir Comida
                </Button>
              <List>
                <ListItem>
                    <ListItemIcon><Filter1OutlinedIcon color="primary" /></ListItemIcon>
                    <ListItemText primary={"Elige tu orden usando nuestro whatsapp."} />
                </ListItem>
                <ListItem>
                    <ListItemIcon><Filter2OutlinedIcon color="primary" /></ListItemIcon>
                    <ListItemText primary={"Registrate como usuario y guarda tu medio de pago."} />
                </ListItem>
                <ListItem>
                    <ListItemIcon><Filter3OutlinedIcon color="primary" /></ListItemIcon>
                    <ListItemText primary={"Retira tu orden en el restaurante ¡y listo!"} />
                </ListItem>
              </List>              
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
const page = withRouter(injectIntl(IndexPage));
export default page;
