import { api } from "../libs/utils";

export const registro = async (form) => {
  return await api({
    url: "/Clientes/Clientes",
    method: "post",
    data: {
      ...form,
      direccion: "Via a la costa",
      idComuna: 1,
      idRegion: 1,
      estado: "A",
      fechaActualizacion: "2021-11-24",
    },
  });
};
