import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as api from "../services/confirmaregistro";
import useNotifyError from "../hooks/useNotifyError";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { AppContext } from "../context/AppContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    children: {
      backgroundColor: "yellow",
      margin: "2rem",
    },
    boxCentradoVerticalmente: {
      height: 430,
      display: "flex",
      alignItems: "center",
    },
    contenedor: {
      height: "100%",
    },
    img: {
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
    },
  })
);
const ConfirmRegister = (props) => {
	const [titulo, setTitulo] = React.useState("");
	const [mensaje, setMensaje] = React.useState("");
	const classes = useStyles();
	const { setLoading } = React.useContext(AppContext);
	const notifyError = useNotifyError();
	useEffect(()  =>  {
		async function consumirAPI(){
			try {
				setLoading(true);
				const idcli = localStorage.getItem("idcli");
				const token = localStorage.getItem("token");
				const confirmar=await api.confirmregistro(token, idcli);
				if (confirmar===0)
				{
					setTitulo("Registro Exitoso");
					setMensaje("Tu medio de pago ha sido registrado con éxito.");
				}
				else if (confirmar===-96)
				{
					console.log(confirmar);
					setTitulo("Registro Cancelado");
					setMensaje("Ha cancelado el registro de su tarjeta.");
				}
				else if (confirmar===-1)
				{
					setTitulo("Transacción Rechazada");
					setMensaje("La tarjeta ingresada ha sido rechazada.");
				}
				else
				{
					setTitulo("Problemas Tarjeta");
					setMensaje("La tarjeta ingresada presenta problemas.");
				}
				return Promise.resolve(true);
			  } catch (error) {
				notifyError(error);
			  } finally {	
				setLoading(false);
			}
		}
		consumirAPI();
    }, [setLoading])
	return (
		<Box
      px={8}
      py={8}
      style={{ background: "#FFFFFF" }}
      className={classes.contenedor}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.contenedor}
      >
        <Grid item xs={12} sm={12} md={5} xl={5} lg={5}>
          <Box style={{ background: "#FFFFFF" }}>
          <Box mx={2} style={{textAlign:"center"}}>
                <Box 
                    component="img"
                    sx={{
                        height: 58,
                        width: 200,
                        maxHeight: { xs: 58, md: 58 },
                        maxWidth: { xs: 200, md: 200 },
                    }}
                    alt="Mensaje"
                    src="/correo_face.png"
                />
              </Box>
            <br></br><br></br>
            <Box mx={2} style={{ textAlign: "center" }}>
                <Box style={{ paddingBottom: "20px" }}>
                    <Typography
                        align="center"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                    >{titulo}</Typography>
                </Box>
            </Box>
              <Box mx={2} style={{textAlign:"center"}}>
              <Box style={{ paddingBottom: "20px" }}>
                <Typography
                  align="center"
                  style={{ fontSize: "20px", fontWeight:"bold" }}
                >{mensaje}</Typography>
              </Box>
              </Box>
              <br></br>
            <Box mx={2} style={{textAlign:"center"}}>
                <Box 
                    component="img"
                    sx={{
                        height: 58,
                        width: 200,
                        maxHeight: { xs: 58, md: 58 },
                        maxWidth: { xs: 200, md: 200 },
                    }}
                    alt="Trafood"
                    src="/TRAFOOD_ICONO.png"
                />
              </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
		
	);
};
const page = withRouter(injectIntl(ConfirmRegister));
export default page;
