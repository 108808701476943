import React from "react";
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export const AppContext = React.createContext();
export const AppProvider = ({ children }) => {
	const isMobile = false;
	const [loading, setLoading] = React.useState(false);
	const [openDrawer, setOpenDrawer] = React.useState(true);
	const [navigationListStack, setNavigationListStack] = React.useState([]);
	const [userInformation, setUserInformation] = React.useState({});
	const [userParameters, setUserParameters] = React.useState({});
	// const [isAuthenticated, setIsAuthenticated] = React.useState(false);

	// React.useEffect(() => {
	// 	setIsAuthenticated(!isEmpty(authentication));
	// }, [authentication]);

	return (
		<AppContext.Provider
			value={{
				isMobile,
				openDrawer,
				setOpenDrawer,
				// setAuthentication,
				// isAuthenticated,
				loading,
				setLoading,
				navigationListStack,
				setNavigationListStack,
				userInformation,
				setUserInformation,
				userParameters,
				setUserParameters,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
