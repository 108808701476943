import { useSnackbar } from "notistack";
const useNotifyError = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	const handleError = (err) => {
		enqueueSnackbar(err.response?.data?.message || err.message, {
			variant: "error",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
			preventDuplicate: true,
			autoHideDuration: 4000,
		});
	};

	return handleError;
};

export default useNotifyError;
